import { Button, Result } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { SmileOutlined } from '@ant-design/icons'
import './style.less'
import { httpGet } from '../../services/http'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Pagewrapper from '../../components/PageWrapper'

export default function StartGuidePage() {
    const data = useStaticQuery(graphql`
        query {
            s1: file(relativePath: { eq: "multi-platform.png" }) {
                childImageSharp {
                    fluid(maxWidth: 600, maxHeight: 500) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)
    useEffect(() => {
        httpGet('/landing/article/guide-index/read')
    }, [])

    return (
        <Pagewrapper>
            <div id="progressBarContainer">
                <div
                    id="progressBar"
                    style={{
                        transform: 'scale(0,0)',
                        opacity: 0,
                    }}
                />
            </div>
            <Result
                icon={
                    <Img
                        className="guide-image"
                        alt="multi-platform"
                        fluid={data.s1.childImageSharp.fluid}
                    />
                }
                title="Welcome to LL § 33A Compliance Helper!"
                subTitle={
                    <div style={{ maxWidth: 500, margin: 'auto' }}>
                        <h3>Complete these steps to get a proper introduction to the App.</h3>
                        <SmileOutlined style={{ fontSize: 40 }} />
                        <p>
                            ll33a.dk is cloud based and cross-platform.
                            <br />
                            You can access from all your devices!
                        </p>
                    </div>
                }
                extra={[
                    <Link to="/app/">
                        <Button>skip</Button>
                    </Link>,
                    <Link to="/onboarding/pre-guide/">
                        <Button type="primary">Next</Button>
                    </Link>,
                ]}
            />
        </Pagewrapper>
    )
}
